var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"gift-menu-card d-flex flex-row align-center py-4 mb-8",attrs:{"to":_vm.link}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(_vm.benefit.giftCertificate.categoryProposal.img)?_c('img',{staticClass:"w-100",attrs:{"contain":"","src":_vm.benefit.giftCertificate.categoryProposal.img,"alt":""}}):_vm._e()]),_c('v-col',{staticClass:"pa-4",attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"expiration-date text-caption grey lighten-3 mb-3 pa-1",domProps:{"innerHTML":_vm._s(
            `<span class='font-weight-bold text-uppercase'>${_vm.$t(
              'checkout.expires'
            )}</span>: ${_vm.$t('benefits.endDate')} 
              ${_vm.$dayjs(_vm.benefit.giftCertificate.endDate).format('DD/MM')}`
          )}}),(_vm.benefit?.giftCertificate?.categoryProposal?.name)?_c('div',{staticClass:"text-h4 font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.benefit?.giftCertificate?.categoryProposal?.name)}}):_vm._e(),(_vm.benefit?.giftCertificate?.categoryProposal?.descr)?_c('div',{staticClass:"text-body-2 description",domProps:{"innerHTML":_vm._s(_vm.benefit?.giftCertificate?.categoryProposal?.descr)}}):_vm._e(),(_vm.benefit?.giftCertificate?.categoryProposal?.content)?_c('div',{staticClass:"text-body-2 description",domProps:{"innerHTML":_vm._s(_vm.benefit?.giftCertificate?.categoryProposal?.content)}}):_vm._e()]),_vm._l((_vm.benefit.giftEventThresholdResults),function(result,i){return _c('div',{key:'result_' + _vm.index + '_' + i,staticClass:"mb-2"},[(result.currentAmount < result.thresholdAmount)?_c('div',{staticClass:"flex-grow-1 font-weight-bold text-end"},[_vm._v(" "+_vm._s(_vm.$n(result.currentAmount))+"/"+_vm._s(result.thresholdAmount)+" "+_vm._s(result.um)+" ")]):_c('div',{staticClass:"flex-grow-1 font-weight-bold text-end"},[_vm._v(" Obiettivo raggiungo ")]),_c('v-progress-linear',{attrs:{"color":result.currentAmount < result.thresholdAmount
              ? 'warning'
              : 'success darken1',"value":result.completionPercentage,"aria-label":"Caricamento in corso","rounded":"","height":"10"}})],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }