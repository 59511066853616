<template>
  <div class="profile-gift-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <CategoryTitle :category="category" />
    <template
      v-if="
        (filteredAutoAssignedGifts && filteredAutoAssignedGifts.length > 0) ||
          (filteredManualAssignedGifts &&
            filteredManualAssignedGifts.length > 0)
      "
    >
      <h2 class="mb-6">{{ $t("profile.gifts.autoassigned") }}</h2>

      <gift-menu-content
        :benefits="benefits"
        :giftCertificatesReceived="userGiftCertificatesReceived"
        @addUserGift="addUserGift"
      />

      <GiftList
        v-if="filteredAutoAssignedGifts.length > 0"
        :giftList="filteredAutoAssignedGifts"
        @reload="reload"
      />
      <h2 class="mb-6">{{ $t("profile.gifts.manual") }}</h2>
      <GiftList
        v-if="filteredManualAssignedGifts.length > 0"
        :giftList="filteredManualAssignedGifts"
        @reload="reload"
      />
    </template>
    <div v-else-if="!loading" class="empty-gifts">
      {{ $t("profile.gifts.emptyGifts") }}
    </div>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />

    <!-- Invita un amico -->
  </div>
</template>
<style lang="scss">
.profile-gift-container {
  .invite-card {
    height: 210px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: auto;
    }
    .v-image {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import GiftList from "@/components/gift/GiftList.vue";
import login from "~/mixins/login";
import category from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapProposalComponent } from "@/commons/service/ebsn";
import userService from "~/service/userService";
import get from "lodash/get";
import GiftMenuContent from "@/components/gift/GiftMenuContent.vue";
import baskoCustomService from "@/service/baskoCustomService";

export default {
  name: "ProfileGift",
  mixins: [category, deliveryReactive, login],
  components: { CategoryTitle, GiftList, GiftMenuContent },
  data() {
    return {
      loading: true,
      autoAssignedGifts: [],
      manualAssignedGifts: [],
      benefits: []
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      userGiftCertificatesReceived: "cart/getGiftCertificatesReceived"
    }),
    ...mapProposalComponent({
      header: "header",
      footer: "footer"
    }),
    filteredAutoAssignedGifts() {
      return this.autoAssignedGifts
        .filter(
          gift =>
            !get(
              gift,
              "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
            ) == true
        )
        .filter(
          gift =>
            this.benefits
              .map(benefit => benefit.giftCertificate.giftCertificateId)
              .includes(gift.giftCertificateId) === false
        );
    },
    filteredManualAssignedGifts() {
      return this.manualAssignedGifts.filter(
        gift =>
          !get(
            gift,
            "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
          ) == true
      );
    }
  },
  methods: {
    async addUserGift(userGiftCertificateId) {
      await this.addGiftCode(userGiftCertificateId);
    },
    async reload() {
      console.log("ggg reloading");
      try {
        console.log("ggg reloading inside the try");

        this.loading = true;
        this.autoAssignedGifts = await userService.listGiftCertificate("auto");
        this.manualAssignedGifts = await userService.listGiftCertificate(
          "manual"
        );

        let response = await baskoCustomService.getBenefits();
        console.log("ggg response", response);
        this.benefits = response.giftCertificateThresholds.slice(0, 3);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
