<template>
  <v-card
    class="gift-menu-card d-flex flex-row align-center py-4 mb-8"
    :to="link"
  >
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <img
          class="w-100"
          v-if="benefit.giftCertificate.categoryProposal.img"
          contain
          :src="benefit.giftCertificate.categoryProposal.img"
          alt=""
        />
      </v-col>
      <v-col cols="12" sm="8" class="pa-4">
        <div class="d-flex flex-column">
          <div
            class="expiration-date text-caption grey lighten-3 mb-3 pa-1"
            v-html="
              `<span class='font-weight-bold text-uppercase'>${$t(
                'checkout.expires'
              )}</span>: ${$t('benefits.endDate')} 
                ${$dayjs(benefit.giftCertificate.endDate).format('DD/MM')}`
            "
          ></div>
          <div
            v-if="benefit?.giftCertificate?.categoryProposal?.name"
            class="text-h4 font-weight-bold"
            v-html="benefit?.giftCertificate?.categoryProposal?.name"
          ></div>
          <div
            v-if="benefit?.giftCertificate?.categoryProposal?.descr"
            class="text-body-2 description"
            v-html="benefit?.giftCertificate?.categoryProposal?.descr"
          ></div>
          <div
            v-if="benefit?.giftCertificate?.categoryProposal?.content"
            class="text-body-2 description"
            v-html="benefit?.giftCertificate?.categoryProposal?.content"
          ></div>
        </div>
        <div
          v-for="(result, i) in benefit.giftEventThresholdResults"
          :key="'result_' + index + '_' + i"
          class="mb-2"
        >
          <div
            v-if="result.currentAmount < result.thresholdAmount"
            class="flex-grow-1 font-weight-bold text-end"
          >
            {{ $n(result.currentAmount) }}/{{ result.thresholdAmount }}
            {{ result.um }}
          </div>
          <div v-else class="flex-grow-1 font-weight-bold text-end">
            Obiettivo raggiungo
          </div>
          <v-progress-linear
            :color="
              result.currentAmount < result.thresholdAmount
                ? 'warning'
                : 'success darken1'
            "
            :value="result.completionPercentage"
            aria-label="Caricamento in corso"
            rounded
            height="10"
          ></v-progress-linear>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.gift-menu-card {
  .expiration-date {
    width: fit-content;
  }
}
</style>
<script>
import get from "lodash/get";

export default {
  name: "GiftMenuCard",
  props: {
    benefit: {
      type: Object,
      required: true
    },
    index: { type: Number, required: false }
  },
  computed: {
    name() {
      return get(this.benefit, "giftCertificate.categoryProposal.content")
        ? get(this.benefit, "giftCertificate.categoryProposal.content")
        : this.benefit.giftCertificate.name;
    },
    description() {
      return get(this.benefit, "giftCertificate.categoryProposal.content")
        ? get(this.benefit, "giftCertificate.categoryProposal.content")
        : this.benefit.giftCertificate.description;
    },
    link() {
      if (get(this.benefit.giftCertificate, "categoryProposal.proposalUrl")) {
        return this.benefit.giftCertificate.categoryProposal.proposalUrl;
      } else if (get(this.benefit.giftCertificate, "categoryProposal.slug")) {
        return {
          name: "Category",
          params: {
            pathMatch: this.benefit.giftCertificate.categoryProposal.slug
          }
        };
      } else {
        return null;
      }
    }
  },
  methods: {
    getBarWidth(currentAmount, thresholdAmount) {
      if (currentAmount == 0) return 0;
      return (currentAmount / thresholdAmount) * 100;
    }
  }
};
</script>
